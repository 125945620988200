/* ------------------------------------------------------------------------------
 *
 *  # Default Bootstrap variable overrides
 *
 *  Variables should follow the `$component-state-property-size` formula for
 *  consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
 *  Also includes custom variables, all marked with "!default" flag.
 *
 * ---------------------------------------------------------------------------- */


//
// Color system
//
// Includes grey palette, contextual colors and main theme colors

// Grey shades
$white:       #fff;
$gray-100:    #fafafa;
$gray-200:    #f5f5f5;
$gray-300:    #eee;
$gray-400:    #ddd;
$gray-500:    #ccc;
$gray-600:    #999;
$gray-700:    #777;
$gray-800:    #555;
$gray-900:    #333;
$black:       #000;

// Grey colors map
$grays: ();
$grays: map-merge((
    "100":    $gray-100,
    "200":    $gray-200,
    "300":    $gray-300,
    "400":    $gray-400,
    "500":    $gray-500,
    "600":    $gray-600,
    "700":    $gray-700,
    "800":    $gray-800,
    "900":    $gray-900
), $grays);


//
// Blue palette
// * Used for primary color
//

$blue:       #2196F3;

$blue-100:   tint-color($blue, 90%) !default;
$blue-200:   tint-color($blue, 60%) !default;
$blue-300:   tint-color($blue, 40%) !default;
$blue-400:   tint-color($blue, 20%) !default;
$blue-500:   $blue !default;
$blue-600:   shade-color($blue, 20%) !default;
$blue-700:   shade-color($blue, 40%) !default;
$blue-800:   shade-color($blue, 60%) !default;
$blue-900:   shade-color($blue, 80%) !default;


//
// Slate palette
// * Used for secondary color
//

$slate:       #99d9f0 !default;

$slate-100:   tint-color($slate, 90%) !default;
$slate-200:   tint-color($slate, 60%) !default;
$slate-300:   tint-color($slate, 40%) !default;
$slate-400:   tint-color($slate, 20%) !default;
$slate-500:   $slate !default;
$slate-600:   shade-color($slate, 20%) !default;
$slate-700:   shade-color($slate, 40%) !default;
$slate-800:   shade-color($slate, 60%) !default;
$slate-900:   shade-color($slate, 80%) !default;


//
// Green palette
// * Used for success color
//

$green:       #25b372;

$green-100:   tint-color($green, 90%) !default;
$green-200:   tint-color($green, 60%) !default;
$green-300:   tint-color($green, 40%) !default;
$green-400:   tint-color($green, 20%) !default;
$green-500:   $green !default;
$green-600:   shade-color($green, 20%) !default;
$green-700:   shade-color($green, 40%) !default;
$green-800:   shade-color($green, 60%) !default;
$green-900:   shade-color($green, 80%) !default;


//
// Cyan palette
// * Used for info color
//

$cyan:       #2cbacc;

$cyan-100:   tint-color($cyan, 90%) !default;
$cyan-200:   tint-color($cyan, 60%) !default;
$cyan-300:   tint-color($cyan, 40%) !default;
$cyan-400:   tint-color($cyan, 20%) !default;
$cyan-500:   $cyan !default;
$cyan-600:   shade-color($cyan, 20%) !default;
$cyan-700:   shade-color($cyan, 40%) !default;
$cyan-800:   shade-color($cyan, 60%) !default;
$cyan-900:   shade-color($cyan, 80%) !default;


//
// Orange palette
// * Used for warning color
//

$orange:       #f58646;

$orange-100:   tint-color($orange, 90%) !default;
$orange-200:   tint-color($orange, 60%) !default;
$orange-300:   tint-color($orange, 40%) !default;
$orange-400:   tint-color($orange, 20%) !default;
$orange-500:   $orange !default;
$orange-600:   shade-color($orange, 20%) !default;
$orange-700:   shade-color($orange, 40%) !default;
$orange-800:   shade-color($orange, 60%) !default;
$orange-900:   shade-color($orange, 80%) !default;


//
// Red palette
// * Used for danger color
//

$red:       #ef5350;

$red-100:   tint-color($red, 90%) !default;
$red-200:   tint-color($red, 60%) !default;
$red-300:   tint-color($red, 40%) !default;
$red-400:   tint-color($red, 20%) !default;
$red-500:   $red !default;
$red-600:   shade-color($red, 20%) !default;
$red-700:   shade-color($red, 40%) !default;
$red-800:   shade-color($red, 60%) !default;
$red-900:   shade-color($red, 80%) !default;


//
// Indigo
//

$indigo:       #5C6BC0;

$indigo-100:   tint-color($indigo, 90%) !default;
$indigo-200:   tint-color($indigo, 60%) !default;
$indigo-300:   tint-color($indigo, 40%) !default;
$indigo-400:   tint-color($indigo, 20%) !default;
$indigo-500:   $indigo !default;
$indigo-600:   shade-color($indigo, 20%) !default;
$indigo-700:   shade-color($indigo, 40%) !default;
$indigo-800:   shade-color($indigo, 60%) !default;
$indigo-900:   shade-color($indigo, 80%) !default;


//
// Pink palette
//

$pink:       #f35c86;

$pink-100:   tint-color($pink, 90%) !default;
$pink-200:   tint-color($pink, 60%) !default;
$pink-300:   tint-color($pink, 40%) !default;
$pink-400:   tint-color($pink, 20%) !default;
$pink-500:   $pink !default;
$pink-600:   shade-color($pink, 20%) !default;
$pink-700:   shade-color($pink, 40%) !default;
$pink-800:   shade-color($pink, 60%) !default;
$pink-900:   shade-color($pink, 80%) !default;


//
// Purple palette
//

$purple:       #8e70c1;

$purple-100:   tint-color($purple, 90%) !default;
$purple-200:   tint-color($purple, 60%) !default;
$purple-300:   tint-color($purple, 40%) !default;
$purple-400:   tint-color($purple, 20%) !default;
$purple-500:   $purple !default;
$purple-600:   shade-color($purple, 20%) !default;
$purple-700:   shade-color($purple, 40%) !default;
$purple-800:   shade-color($purple, 60%) !default;
$purple-900:   shade-color($purple, 80%) !default;


//
// Teal palette
//

$teal:       #26A69A;

$teal-100:   tint-color($teal, 90%) !default;
$teal-200:   tint-color($teal, 60%) !default;
$teal-300:   tint-color($teal, 40%) !default;
$teal-400:   tint-color($teal, 20%) !default;
$teal-500:   $teal !default;
$teal-600:   shade-color($teal, 20%) !default;
$teal-700:   shade-color($teal, 40%) !default;
$teal-800:   shade-color($teal, 60%) !default;
$teal-900:   shade-color($teal, 80%) !default;


//
// Yellow
//

$yellow:       #ffd648;

$yellow-100:   tint-color($yellow, 90%) !default;
$yellow-200:   tint-color($yellow, 60%) !default;
$yellow-300:   tint-color($yellow, 40%) !default;
$yellow-400:   tint-color($yellow, 20%) !default;
$yellow-500:   $yellow !default;
$yellow-600:   shade-color($yellow, 20%) !default;
$yellow-700:   shade-color($yellow, 40%) !default;
$yellow-800:   shade-color($yellow, 60%) !default;
$yellow-900:   shade-color($yellow, 80%) !default;


//
// Main colors map
// * Used for CSS variables
//

$colors: ();
$colors: map-merge((
    "blue":          $blue,
    "blue-100":      $blue-100,
    "red":           $red,
    "red-100":       $red-100,
    "orange":        $orange,
    "orange-100":    $orange-100,
    "green":         $green,
    "green-100":     $green-100,
    "cyan":          $cyan,
    "cyan-100":      $cyan-100,
    "gray":          $gray-600,
    "gray-dark":     $gray-900
), $colors);


//
// Contextual colors
//

$primary:          $blue;
$secondary:        $slate;
$success:          $green;
$info:             $cyan;
$warning:          $orange;
$danger:           $red;
$light:            #f0f2f5;
$light-100:        rgba($white, 0.1) !default;
$dark:             #ffffff;
$dark-100:         rgba($black, 0.1) !default;


// Contextual colors map
$theme-colors: ();
$theme-colors: map-merge((
    "primary":          $primary,
    "primary-100":      $blue-100,
    "secondary":        $secondary,
    "secondary-100":    $slate-100,
    "success":          $success,
    "success-100":      $green-100,
    "info":             $info,
    "info-100":         $cyan-100,
    "warning":          $warning,
    "warning-100":      $orange-100,
    "danger":           $danger,
    "danger-100":       $red-100,
    "white":            $white,
    "light":            $light,
    "light-100":        $light-100,
    "dark":             $dark,
    "dark-100":         $dark-100,

    "teal":             $teal,
    "teal-100":         $teal-100,
    "indigo":           $indigo,
    "indigo-100":       $indigo-100,
    "purple":           $purple,
    "purple-100":       $purple-100,
    "pink":             $pink,
    "pink-100":         $pink-100,
    "yellow":           $yellow,
    "yellow-100":       $yellow-100
), $theme-colors);


// Set a specific jump point for requesting color jumps
$theme-color-interval:    8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:    160;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:     $gray-900;
$yiq-text-light:    $white;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29"),
);


//
// Options
//
// Quickly modify global styling by enabling or disabling optional features. More in _config.scss

$enable-caret:                               true;
$enable-rounded:                             true;
$enable-shadows:                             true;
$enable-gradients:                           false;
$enable-transitions:                         true;
$enable-prefers-reduced-motion-media-query:  true;
$enable-hover-media-query:                   false;
$enable-grid-classes:                        true;
$enable-pointer-cursor-for-buttons:          true;
$enable-print-styles:                        true;
$enable-responsive-font-sizes:               false;
$enable-validation-icons:                    true;
$enable-deprecation-messages:                false;


//
// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer:    1.25rem;
$spacers:   ();
$spacers: map-merge((
    0:    0,
    1:    ($spacer * .25),
    2:    ($spacer * .5),
    3:    $spacer,
    4:    ($spacer * 1.5),
    5:    ($spacer * 3)
), $spacers);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge((
    25:      25%,
    50:      50%,
    75:      75%,
    100:     100%,
    auto:    auto
), $sizes);


//
// Body
//
// Settings for the `<body>` element.

$body-bg:       #f5f5f5;
$body-color:    $gray-900;


//
// Links
//
// Style anchor elements.

$link-color:               $blue-500;
$link-decoration:          none;
$link-hover-color:         darken($link-color, 15%);
$link-hover-decoration:    none;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;


//
// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:    0.625rem;


//
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 769px,
  lg: 992px,
  xl: 1200px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


//
// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");


//
// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:              12;
$grid-gutter-width:         1.25rem;
$grid-row-columns:          6;


// Components
//
// Define common padding and border radius sizes and more.

// Default borders
$border-width:              1px;
$border-color:              $gray-400;

// Border radius
$border-radius:             0.25rem;
$border-radius-lg:          0.3125rem;
$border-radius-sm:          0.1875rem;
$rounded-pill:              50rem;


// Base shadows
$box-shadow-sm:             0 .125rem .25rem rgba($black, .075);
$box-shadow:                0 .5rem 1rem rgba($black, .15);
$box-shadow-lg:             0 1rem 3rem rgba($black, .175);

// Custom shadows
$shadow-depth1:             0 1px 3px rgba($black, 0.12), 0 1px 2px rgba($black, 0.24) !default;
$shadow-inverse-depth1:     0 -1px 3px rgba($black, 0.12), 0 -1px 2px rgba($black, 0.24) !default;
$shadow-depth2:             0 3px 6px rgba($black, 0.16), 0 3px 6px rgba($black, 0.23) !default;
$shadow-depth3:             0 10px 20px rgba($black, 0.19), 0 6px 6px rgba($black, 0.23) !default;
$shadow-depth4:             0 14px 28px rgba($black, 0.25), 0 10px 10px rgba($black, 0.22) !default;
$shadow-depth5:             0 19px 38px rgba($black, 0.3), 0 15px 12px rgba($black, 0.22) !default;

// Active state colors
$component-active-color:    $white;
$component-active-bg:       $blue-500;

// Caret
$caret-font-size:           1rem !default;
$caret-width:               0.3em;
$caret-vertical-align:      middle;
$caret-spacing:             map-get($spacers, 2) * 0.75;

// Transitions
$transition-base:           all .15s ease-in-out;
$transition-fade:           opacity .15s linear;
$transition-collapse:       height .15s ease;

$embed-responsive-aspect-ratios: ();
$embed-responsive-aspect-ratios: join(
  (
    (21 9),
    (16 9),
    (4 3),
    (1 1),
  ),
  $embed-responsive-aspect-ratios
);


//
// Typography
//
// Font, line-height, and color for body text, headings, and more.

// Font families
$font-family-sans-serif:     "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:      SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:           $font-family-sans-serif;

// Font sizes
$font-size-base:             0.875rem; // Assumes the browser default, typically `16px`
$font-size-lg:               ($font-size-base + 0.0625rem);
$font-size-sm:               ($font-size-base - 0.0625rem);
$font-size-xs:               ($font-size-base - (0.0625rem * 2)) !default;

// Font weights
$font-weight-thin:           100 !default;
$font-weight-light:          300;
$font-weight-normal:         400;
$font-weight-semibold:       500 !default;
$font-weight-bold:           700;
$font-weight-black:          900 !default;
$font-weight-base:           $font-weight-normal;

$font-weight-lighter:        lighter;
$font-weight-bolder:         bolder;

// Line heights
$line-height-base:           1.5715;
$line-height-lg:             1.4667;
$line-height-sm:             1.6924;
$line-height-xs:             1.8334 !default;

// Computed line heights (custom vars)
$line-height-computed:       decimal-round($font-size-base * $line-height-base, 3, floor) !default;
$line-height-computed-lg:    decimal-round($font-size-lg * $line-height-lg, 3, floor) !default;
$line-height-computed-sm:    decimal-round($font-size-sm * $line-height-sm, 3, floor) !default;

// Heading sizes
$h1-font-size:               ($font-size-base + (0.125rem * 6));
$h2-font-size:               ($font-size-base + (0.125rem * 5));
$h3-font-size:               ($font-size-base + (0.125rem * 4));
$h4-font-size:               ($font-size-base + (0.125rem * 3));
$h5-font-size:               ($font-size-base + (0.125rem * 2));
$h6-font-size:               ($font-size-base + 0.125rem);

// Heading options
$headings-margin-bottom:     ($spacer / 2);
$headings-font-family:       null;
$headings-font-weight:       $font-weight-base;
$headings-line-height:       $line-height-base;
$headings-color:             null;
$headings-letter-spacing:    -0.015em !default;

// Display and lead font styles
$display1-size:              6rem;
$display2-size:              5.5rem;
$display3-size:              4.5rem;
$display4-size:              3.5rem;

$display1-weight:            300;
$display2-weight:            300;
$display3-weight:            300;
$display4-weight:            300;
$display-line-height:        $headings-line-height;

$lead-font-size:             ($font-size-base * 1.25);
$lead-font-weight:           300;

// Misc
$small-font-size:            80%;

$text-muted:                 $gray-600;

$blockquote-small-color:      $gray-600;
$blockquote-small-font-size:  $small-font-size;
$blockquote-font-size:        $h6-font-size;
$blockquote-border-width:     5px !default;
$blockquote-border-color:     $gray-300 !default;

$hr-border-color:            $border-color;
$hr-border-width:            $border-width;

$mark-padding:               0.25rem 0.5rem;

$dt-font-weight:             $font-weight-semibold;

$kbd-box-shadow:             inset 0 -0.1rem 0 rgba($black, .25);
$nested-kbd-font-weight:     $font-weight-bold;

$list-inline-padding:        1.25rem;

$mark-bg:                    #fcf8e3;

$hr-margin-y:                $spacer;


//
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-scrollable-max-height:     22.5rem !default;

$table-cell-padding-xl:           1.25rem $card-spacer-x !default;
$table-cell-padding-lg:           1rem $card-spacer-x !default;
$table-cell-padding:              0.75rem $card-spacer-x;
$table-cell-padding-sm:           0.625rem $card-spacer-x;
$table-cell-padding-xs:           0.5rem $card-spacer-x !default;

$table-color:                     $body-color;
$table-bg:                        null;
$table-accent-bg:                 rgba($black, .02);
$table-hover-color:               $table-color;
$table-hover-bg:                  rgba($black, .03);
$table-active-bg:                 rgba($black, .015);

$table-border-width:              $border-width;
$table-border-color:              $border-color;

$table-head-bg:                   $gray-200;
$table-head-color:                $body-color;
$table-th-font-weight:            null;
$table-head-border-color:         darken($table-border-color, 15%) !default;

$table-dark-color:                $white;
$table-dark-bg:                   $gray-900;
$table-dark-accent-bg:            rgba($white, 0.05);
$table-dark-hover-color:          $table-dark-color;
$table-dark-hover-bg:             rgba($white, 0.075);
$table-dark-border-color:         rgba($white, 0.35);
$table-dark-head-border-color:    rgba($white, 0.5) !default;

$table-striped-order:             odd;

$table-caption-color:             $text-muted;

$table-bg-level:                  -11;
$table-border-level:              -8;


//
// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:           0.4375rem;
$input-btn-padding-x:           0.875rem;
$input-btn-font-family:         null;
$input-btn-font-size:           $font-size-base;
$input-btn-line-height:         $line-height-base;

$input-btn-focus-width:         0;
$input-btn-focus-color:         transparent;
$input-btn-focus-box-shadow:    null;

$input-btn-padding-y-sm:        0.3125rem;
$input-btn-padding-x-sm:        0.75rem;
$input-btn-font-size-sm:        $font-size-sm;
$input-btn-line-height-sm:      $line-height-sm;

$input-btn-padding-y-lg:        0.5625rem;
$input-btn-padding-x-lg:        1rem;
$input-btn-font-size-lg:        $font-size-lg;
$input-btn-line-height-lg:      $line-height-lg;

$input-btn-border-width:        $border-width;


//
// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
// Includes custom variables for optional styles

$btn-padding-y:                    $input-btn-padding-y;
$btn-padding-x:                    $input-btn-padding-x;
$btn-font-family:                  $input-btn-font-family;
$btn-font-size:                    $input-btn-font-size;
$btn-line-height:                  $input-btn-line-height;
$btn-white-space:                  null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:                 $input-btn-padding-y-sm;
$btn-padding-x-sm:                 $input-btn-padding-x-sm;
$btn-font-size-sm:                 $input-btn-font-size-sm;
$btn-line-height-sm:               $input-btn-line-height-sm;

$btn-padding-y-lg:                 $input-btn-padding-y-lg;
$btn-padding-x-lg:                 $input-btn-padding-x-lg;
$btn-font-size-lg:                 $input-btn-font-size-lg;
$btn-line-height-lg:               $input-btn-line-height-lg;

$btn-border-width:                 $input-btn-border-width;

$btn-font-weight:                  $font-weight-normal;
$btn-box-shadow:                   null;
$btn-focus-width:                  $input-btn-focus-width;
$btn-focus-box-shadow:             null;
$btn-disabled-opacity:             .65;
$btn-active-box-shadow:            null;

$btn-link-disabled-color:          $gray-600;

$btn-block-spacing-y:              0.5rem;

$btn-border-radius:                $border-radius;
$btn-border-radius-lg:             $border-radius-lg;
$btn-border-radius-sm:             $border-radius-sm;

$btn-transition:                   color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$btn-float-padding:                1rem !default;

$btn-light-bg:                     $light !default;
$btn-light-color:                  color-yiq($btn-light-bg) !default;
$btn-light-border-color:           darken($light, 12.5%) !default;

$btn-light-hover-bg:               darken($btn-light-bg, 5%) !default;
$btn-light-hover-color:            color-yiq($btn-light-hover-bg) !default;
$btn-light-hover-border-color:     darken($btn-light-border-color, 5%);

$btn-light-active-bg:              darken($btn-light-bg, 10%) !default;
$btn-light-active-color:           color-yiq($btn-light-active-bg) !default;
$btn-light-active-border-color:    darken($btn-light-border-color, 10%) !default;


//
// Forms
//
// Basic form components. Includes custom variables for optional styles

$label-margin-bottom:                 0.5rem;

$input-padding-y:                     $input-btn-padding-y;
$input-padding-x:                     $input-btn-padding-x;
$input-font-family:                   $input-btn-font-family;
$input-font-size:                     $input-btn-font-size;
$input-font-weight:                   $font-weight-base;
$input-line-height:                   $input-btn-line-height;

$input-padding-y-sm:                  $input-btn-padding-y-sm;
$input-padding-x-sm:                  $input-btn-padding-x-sm;
$input-font-size-sm:                  $input-btn-font-size-sm;
$input-line-height-sm:                $input-btn-line-height-sm;

$input-padding-y-lg:                  $input-btn-padding-y-lg;
$input-padding-x-lg:                  $input-btn-padding-x-lg;
$input-font-size-lg:                  $input-btn-font-size-lg;
$input-line-height-lg:                $input-btn-line-height-lg;

$input-bg:                            $white;
$input-disabled-bg:                   $gray-100;
$input-disabled-color:                $gray-600 !default;

$input-color:                         $body-color;
$input-border-color:                  $gray-400;
$input-border-width:                  $input-btn-border-width;
$input-box-shadow:                    null;

$input-border-radius:                 $border-radius;
$input-border-radius-lg:              $border-radius-lg;
$input-border-radius-sm:              $border-radius-sm;

$input-focus-bg:                      null;
$input-focus-border-color:            $primary;
$input-focus-color:                   $input-color;
$input-focus-width:                   $input-btn-focus-width;
$input-focus-box-shadow:              $input-btn-focus-box-shadow;

$input-placeholder-color:             $gray-600;
$input-plaintext-color:               $body-color;
$input-placeholder-light-color:       $white !default;

$input-height-border:                 $input-border-width * 2;

$input-height-inner:                  add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half:             add($input-line-height * .5em, $input-padding-y);
$input-height-inner-quarter:          add($input-line-height * .25em, $input-padding-y / 2);

$input-height:                        add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
$input-height-sm:                     add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg:                     add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false));

$input-transition:                    border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-text-margin-top:                0.5rem;


// Form checks
$form-check-input-gutter:             1.25rem + map-get($spacers, 2);
$form-check-input-margin-y:           0.3rem;
$form-check-input-margin-x:           0.25rem;

$form-check-inline-margin-x:          1.25rem;
$form-check-inline-input-margin-x:    0.625rem;

$form-check-margin-y:                 0.5rem !default;
$form-check-disabled-color:           $gray-600 !default;

// Input group
$form-grid-gutter-width:              10px;
$form-group-margin-bottom:            $spacer;

$input-group-addon-color:             $input-color;
$input-group-addon-bg:                $gray-100;
$input-group-addon-border-color:      $input-border-color;

// Form validation
$form-feedback-margin-top:            $form-text-margin-top;
$form-feedback-font-size:             $font-size-sm;
$form-feedback-valid-color:           $green-500;
$form-feedback-invalid-color:         $red-500;

$form-feedback-icon-valid-color:      $form-feedback-valid-color;
$form-feedback-icon-valid:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color:    $form-feedback-invalid-color;
$form-feedback-icon-invalid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");

$form-validation-states: ();
$form-validation-states: map-merge(
  (
    "valid": (
      "color": $form-feedback-valid-color,
      "icon": $form-feedback-icon-valid
    ),
    "invalid": (
      "color": $form-feedback-invalid-color,
      "icon": $form-feedback-icon-invalid
    ),
  ),
  $form-validation-states
);


//
// Custom forms
//
// Custom CSS styles for select, checkboxes, radios and file input

// Common
$custom-forms-transition:                               background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

// Checkboxes and radios
$custom-control-gutter:                                 0.75rem;
$custom-control-spacer-x:                               1rem;
$custom-control-cursor:                                 pointer;

$custom-control-indicator-size:                         1.25rem;
$custom-control-indicator-bg:                           $input-bg;
$custom-control-indicator-bg-size:                      50% 50%;
$custom-control-indicator-box-shadow:                   0 0 0 0 transparent;
$custom-control-indicator-border-color:                 $input-border-color;
$custom-control-indicator-border-width:                 ($border-width * 2);

$custom-control-label-color:                            null;

$custom-control-indicator-disabled-bg:                  $custom-control-indicator-bg;
$custom-control-label-disabled-color:                   $gray-600;

$custom-control-indicator-checked-color:                $white;
$custom-control-indicator-checked-bg:                   $component-active-bg;
$custom-control-indicator-checked-disabled-bg:          $custom-control-indicator-checked-bg;
$custom-control-indicator-checked-box-shadow:           null;
$custom-control-indicator-checked-border-color:         $custom-control-indicator-checked-bg;

$custom-control-indicator-focus-box-shadow:             $input-focus-box-shadow;
$custom-control-indicator-focus-border-color:           darken($input-border-color, 10%);

$custom-control-indicator-active-color:                 null;
$custom-control-indicator-active-bg:                    null;
$custom-control-indicator-active-box-shadow:            null;
$custom-control-indicator-active-border-color:          null;

$custom-checkbox-indicator-border-radius:               $border-radius-sm;
$custom-checkbox-indicator-icon-checked:                url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='#{$custom-control-indicator-checked-color}' d='M13.5 2l-7.5 7.5-3.5-3.5-2.5 2.5 6 6 10-10z'/></svg>");
$custom-checkbox-inverse-indicator-icon-checked:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='#{$black}' d='M13.5 2l-7.5 7.5-3.5-3.5-2.5 2.5 6 6 10-10z'/></svg>") !default;

$custom-checkbox-indicator-indeterminate-bg:            $custom-control-indicator-bg;
$custom-checkbox-indicator-indeterminate-color:         $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>");
$custom-checkbox-indicator-indeterminate-box-shadow:    null;
$custom-checkbox-indicator-indeterminate-border-color:  $custom-control-indicator-border-color;

$custom-radio-indicator-border-radius:                  50%;
$custom-radio-indicator-icon-checked:                   url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>");
$custom-radio-inverse-indicator-icon-checked:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$black}'/></svg>") !default;

// Switch
$custom-switch-width:                           $custom-control-indicator-size * 1.75;
$custom-switch-indicator-border-radius:         $custom-control-indicator-size / 2;
$custom-switch-indicator-size:                  $custom-control-indicator-size - (rem-calc($custom-control-indicator-border-width) * 2);
$custom-switch-off-color:                       $gray-600 !default;
$custom-switch-inverse-off-color:               rgba($white, 0.5) !default;
$custom-switch-on-color:                        $primary !default;
$custom-switch-inverse-on-color:                rgba($white, 0.5) !default;
$custom-switch-indicator-color:                 $white !default;
$custom-switch-indicator-box-shadow:            0 0.0625rem 0.125rem rgba($black, .25) !default;

// Select
$custom-select-padding-y:             $input-padding-y;
$custom-select-padding-x:             $input-padding-x;
$custom-select-font-family:           $input-font-family;
$custom-select-font-size:             $input-font-size;
$custom-select-height:                $input-height;
$custom-select-indicator-padding:     $input-padding-x * 1.5;
$custom-select-font-weight:           $input-font-weight;
$custom-select-line-height:           $input-line-height;
$custom-select-color:                 $input-color;
$custom-select-disabled-color:        $gray-600;
$custom-select-bg:                    $input-bg;
$custom-select-disabled-bg:           $gray-200;
$custom-select-bg-size:               8px 10px; // In pixels because image dimensions
$custom-select-indicator-color:       $input-color;
$custom-select-indicator:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>");
$custom-select-background:            escape-svg($custom-select-indicator) right $custom-select-padding-x center / $custom-select-bg-size no-repeat; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right:   add(1em * .75, (2 * $custom-select-padding-y * .75) + $custom-select-padding-x + $custom-select-indicator-padding);
$custom-select-feedback-icon-position:        center right ($custom-select-padding-x + $custom-select-indicator-padding);
$custom-select-feedback-icon-size:            $input-height-inner-half $input-height-inner-half;

$custom-select-border-width:          $input-btn-border-width;
$custom-select-border-color:          $input-border-color;
$custom-select-border-radius:         $input-border-radius;
$custom-select-box-shadow:            none;

$custom-select-focus-border-color:    $input-focus-border-color;
$custom-select-focus-width:           $input-btn-focus-width;
$custom-select-focus-box-shadow:      $input-focus-box-shadow;

$custom-select-padding-y-sm:          $input-padding-y-sm;
$custom-select-padding-x-sm:          $input-padding-x-sm;
$custom-select-font-size-sm:          $input-font-size-sm;
$custom-select-height-sm:             $input-height-sm;

$custom-select-padding-y-lg:          $input-padding-y-lg;
$custom-select-padding-x-lg:          $input-padding-x-lg;
$custom-select-font-size-lg:          $input-font-size-lg;
$custom-select-height-lg:             $input-height-lg;

// Range input
$custom-range-track-width:                      100%;
$custom-range-track-height:                     0.5rem;
$custom-range-track-cursor:                     pointer;
$custom-range-track-bg:                         $gray-300;
$custom-range-track-border-radius:              1rem;
$custom-range-track-box-shadow:                 inset 0 .25rem .25rem rgba($black, .1);

$custom-range-thumb-width:                      1rem;
$custom-range-thumb-height:                     $custom-range-thumb-width;
$custom-range-thumb-bg:                         $component-active-bg;
$custom-range-thumb-border:                     0;
$custom-range-thumb-border-radius:              1rem;
$custom-range-thumb-box-shadow:                 0 .1rem .25rem rgba($black, .1);
$custom-range-thumb-focus-box-shadow:           0 0 0 1px $body-bg, $input-btn-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width:     $input-btn-focus-width; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg:                  lighten($component-active-bg, 35%);
$custom-range-thumb-disabled-bg:                $gray-600;

// File input
$custom-file-height:                $input-height;
$custom-file-height-inner:          $input-height-inner;
$custom-file-focus-border-color:    $input-focus-border-color;
$custom-file-focus-box-shadow:      $input-focus-box-shadow;
$custom-file-disabled-bg:           $input-disabled-bg;

$custom-file-padding-y:             $input-padding-y;
$custom-file-padding-x:             $input-padding-x;
$custom-file-line-height:           $input-line-height;
$custom-file-font-family:           $input-font-family;
$custom-file-font-weight:           $input-font-weight;
$custom-file-color:                 $input-color;
$custom-file-bg:                    $input-bg;
$custom-file-border-width:          $input-border-width;
$custom-file-border-color:          $input-border-color;
$custom-file-border-radius:         $input-border-radius;
$custom-file-box-shadow:            $input-box-shadow;
$custom-file-button-color:          $custom-file-color;
$custom-file-button-bg:             $btn-light-bg;
$custom-file-text: (
  en: "Browse"
);


//
// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown:          1000;
$zindex-sticky:            1020;
$zindex-fixed:             1030;
$zindex-modal-backdrop:    1040;
$zindex-modal:             1050;
$zindex-popover:           1060;
$zindex-tooltip:           1070;


//
// Navs
//
// Base styles for navs. Includes custom variables

$nav-link-padding-y:         0.75rem;
$nav-link-padding-x:         1.25rem;
$nav-link-disabled-color:    $gray-600;

$nav-link-color:             $body-color !default;
$nav-link-hover-color:       $body-color !default;
$nav-link-active-color:      $body-color !default;

$nav-divider-color:          $gray-200;
$nav-divider-margin-y:       ($spacer / 2);


//
// Tabs
//
// Tabs navigation. Includes custom variables

$nav-tabs-border-color:                  $gray-400;
$nav-tabs-border-width:                  $border-width;
$nav-tabs-border-radius:                 $border-radius;
$nav-tabs-link-hover-border-color:       transparent;
$nav-tabs-link-active-color:             $body-color;
$nav-tabs-link-active-bg:                $card-bg;
$nav-tabs-link-active-border-color:      $gray-400 $gray-400 $nav-tabs-link-active-bg;

$nav-tabs-link-color:                    #777 !default;
$nav-tabs-link-hover-color:              $nav-tabs-link-active-color !default;

$nav-tabs-highlight-link-border-width:   2px !default;
$nav-tabs-highlight-link-border-color:   $blue-500 !default;

$nav-tabs-top-link-border-width:         2px !default;
$nav-tabs-top-link-border-color:         $pink-500 !default;

$nav-tabs-bottom-link-border-width:      2px !default;
$nav-tabs-bottom-link-border-color:      $pink-500 !default;

$nav-tabs-solid-bg:                      $gray-200 !default;
$nav-tabs-solid-active-bg:               $blue-500 !default;
$nav-tabs-solid-active-color:            $white !default;


//
// Pills
//
// Pills navigation. Includes custom variables

$nav-pills-border-radius:                $border-radius;
$nav-pills-link-active-color:            $component-active-color;
$nav-pills-link-active-bg:               $component-active-bg;
$nav-pills-link-color:                   $gray-700 !default;
$nav-pills-link-hover-color:             $body-color !default;

$nav-pills-bordered-border-width:        $border-width !default;
$nav-pills-bordered-border-color:        $gray-400 !default;
$nav-pills-bordered-link-hover-color:    $gray-200 !default;
$nav-pills-bordered-disabled-bg:         $gray-100 !default;


//
// Navbar
//
// Includes custom variables for navbar nav links and brand image size

$navbar-border-width:                   $border-width !default;

$navbar-link-padding-y:                 1rem !default;
$navbar-link-padding-x:                 1.25rem !default;

$navbar-link-padding-y-lg:              1.25rem !default;
$navbar-link-padding-x-lg:              1.5rem !default;

$navbar-link-padding-y-sm:              0.75rem !default;
$navbar-link-padding-x-sm:              0.875rem !default;

$navbar-link-highlight-size:            2px !default;
$navbar-light-link-highlight-color:     $pink-400 !default;
$navbar-dark-link-highlight-color:      $white !default;

$navbar-brand-image-size:               1.125rem !default;

$nav-link-height-lg:                    $line-height-computed + $navbar-link-padding-y-lg * 2 !default;
$navbar-brand-height-lg:                $navbar-brand-image-size !default;
$navbar-brand-padding-y-lg:             ($nav-link-height-lg - $navbar-brand-height-lg) / 2 !default;

$nav-link-height-sm:                    $line-height-computed + $navbar-link-padding-y-sm * 2 !default;
$navbar-brand-height-sm:                $navbar-brand-image-size !default;
$navbar-brand-padding-y-sm:             ($nav-link-height-sm - $navbar-brand-height-sm) / 2 !default;

$navbar-padding-y:                      0;
$navbar-padding-x:                      $grid-gutter-width;

$navbar-nav-link-padding-x:             1.25rem;

$navbar-brand-font-size:                0;

$nav-link-height:                       $line-height-computed + $navbar-link-padding-y * 2;
$navbar-brand-height:                   $navbar-brand-image-size;
$navbar-brand-padding-y:                ($nav-link-height - $navbar-brand-height) / 2;

$navbar-toggler-padding-y:              $navbar-link-padding-y;
$navbar-toggler-padding-x:              $navbar-toggler-padding-y * 0.75;
$navbar-toggler-font-size:              $font-size-base;
$navbar-toggler-border-radius:          0;

$navbar-nav-scroll-max-height:          75vh;

$navbar-dark-bg:                        $dark !default;
$navbar-dark-hover-bg:                  rgba($white, 0.1) !default;
$navbar-dark-color:                     $white;
$navbar-dark-hover-color:               $navbar-dark-color;
$navbar-dark-active-color:              $navbar-dark-hover-color;
$navbar-dark-disabled-color:            rgba($white, 0.65);
$navbar-dark-border-color:              rgba($white, 0.1) !default;
$navbar-dark-link-active-bg:            $navbar-dark-hover-bg !default;
$navbar-dark-link-disabled-bg:          transparent !default;
$navbar-dark-toggler-icon-bg:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color:      rgba($white, 0.1);
$navbar-dark-collapse-border-width:     $border-width !default;
$navbar-dark-collapse-border-color:     rgba($white, 0.1) !default;
$navbar-dark-brand-color:               $navbar-dark-active-color;
$navbar-dark-brand-hover-color:         $navbar-dark-brand-color;

$navbar-light-bg:                       $white !default;
$navbar-light-hover-bg:                 rgba($black, 0.04) !default;
$navbar-light-color:                    $body-color;
$navbar-light-hover-color:              $navbar-light-color;
$navbar-light-active-color:             $navbar-light-hover-color;
$navbar-light-disabled-color:           rgba($black, 0.65);
$navbar-light-border-color:             rgba($black, 0.125) !default;
$navbar-light-link-active-bg:           $navbar-light-hover-bg !default;
$navbar-light-link-disabled-bg:         transparent !default;
$navbar-light-toggler-icon-bg:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color:     rgba($black, 0.1);
$navbar-light-collapse-border-width:    $border-width !default;
$navbar-light-collapse-border-color:    $border-color !default;
$navbar-light-brand-color:              $navbar-light-active-color;
$navbar-light-brand-hover-color:        $navbar-light-brand-color;


//
// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-scrollable-max-height:    340px !default;

$dropdown-min-width:                12.5rem;
$dropdown-padding-x:                0;
$dropdown-padding-y:                0.5rem;
$dropdown-spacer:                   0.125rem;
$dropdown-font-size:                $font-size-base;
$dropdown-color:                    $body-color;
$dropdown-bg:                       $white;
$dropdown-border-color:             rgba($black, .15);
$dropdown-border-radius:            $border-radius;
$dropdown-border-width:             $border-width;
$dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width);
$dropdown-divider-bg:               $gray-400;
$dropdown-divider-margin-y:         $dropdown-padding-y;
$dropdown-box-shadow:               0 0.25rem 0.5rem rgba($black, 0.1);

$dropdown-link-color:               $body-color;
$dropdown-link-hover-color:         $dropdown-link-color;
$dropdown-link-hover-bg:            $gray-300;

$dropdown-link-active-color:        $component-active-color;
$dropdown-link-active-bg:           $component-active-bg;

$dropdown-link-disabled-color:      $gray-600;

$dropdown-item-padding-y:           0.5rem;
$dropdown-item-padding-x:           1rem;
$dropdown-item-spacer-y:            1px !default;

$dropdown-icon-spacer-x:            $dropdown-item-padding-x !default;

$dropdown-header-color:             $gray-600;
$dropdown-header-padding:           $dropdown-padding-y $dropdown-item-padding-x;
$dropdown-header-bg:                #f8f8f8 !default;

$dropdown-dark-hover-bg:            rgba($white, 0.1) !default;
$dropdown-dark-active-bg:           rgba($white, 0.2) !default;
$dropdown-dark-disabled-color:      rgba($white, 0.6) !default;
$dropdown-dark-divider-bg:          rgba($white, 0.4) !default;
$dropdown-dark-header-color:        rgba($white, 0.6) !default;
$dropdown-dark-header-bg:           rgba($black, 0.1) !default;


//
// Pagination
//
// Pagination and pager styles. Includes custom variables for optional styles

$pagination-padding-y:                $input-btn-padding-y;
$pagination-padding-x:                $input-btn-padding-x;
$pagination-padding-y-sm:             $input-btn-padding-y-sm;
$pagination-padding-x-sm:             $input-btn-padding-x-sm;
$pagination-padding-y-lg:             $input-btn-padding-y-lg;
$pagination-padding-x-lg:             $input-btn-padding-x-lg;
$pagination-line-height:              $line-height-base;

$pagination-color:                    $btn-light-color;
$pagination-bg:                       $btn-light-bg;
$pagination-border-width:             $btn-border-width;
$pagination-border-color:             $btn-light-border-color;

$pagination-focus-box-shadow:         $input-btn-focus-box-shadow;
$pagination-focus-outline:            0;

$pagination-hover-color:              $btn-light-hover-color;
$pagination-hover-bg:                 $btn-light-hover-bg;
$pagination-hover-border-color:       $btn-light-hover-border-color;

$pagination-active-color:             $component-active-color;
$pagination-active-bg:                $component-active-bg;
$pagination-active-border-color:      $pagination-active-bg;

$pagination-disabled-color:           $gray-500;
$pagination-disabled-bg:              $pagination-bg;
$pagination-disabled-border-color:    $pagination-border-color;

$pagination-border-radius-sm:         $border-radius-sm;
$pagination-border-radius-lg:         $border-radius-lg;

$pagination-flat-spacing:             2px !default;

$pagination-separated-spacing:        ($spacer / 2) !default;


//
// Jumbotron
//
// Headline styles. Not used in the template
$jumbotron-padding:    2rem;
$jumbotron-color:      null;
$jumbotron-bg:         $gray-200;


//
// Cards
//
// Cards and card layouts. Includes custom $card-box-shadow variable

$card-spacer-y:               1.25rem;
$card-spacer-x:               1.25rem;
$card-border-width:           $border-width;
$card-border-radius:          $border-radius;
$card-border-color:           rgba($black, 0.125);
$card-inner-border-radius:    subtract($card-border-radius, $card-border-width);
$card-cap-bg:                 rgba($black, 0.02);
$card-cap-color:              null;
$card-height:                 null;
$card-color:                  null;
$card-bg:                     $white;

$card-img-overlay-padding:    1.25rem;

$card-group-margin:           $spacer;
$card-deck-margin:            $card-group-margin;

$card-columns-count:          3;
$card-columns-gap:            1.25rem;
$card-columns-margin:         $spacer;

$card-header-spacer-y:        1rem !default;
$card-footer-spacer-y:        1rem !default;

$card-box-shadow:             0 1px 2px rgba($black, 0.05) !default;


//
// Popups
//
// Tooltips and popovers

// Tooltip
$tooltip-font-size:            $font-size-base;
$tooltip-max-width:            200px;
$tooltip-color:                $white;
$tooltip-bg:                   $black;
$tooltip-border-radius:        $border-radius;
$tooltip-opacity:              1;
$tooltip-padding-y:            0.375rem;
$tooltip-padding-x:            0.75rem;
$tooltip-margin:               0.3125rem;

$tooltip-arrow-width:          0.5rem;
$tooltip-arrow-height:         0.25rem;
$tooltip-arrow-color:          $tooltip-bg;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y:        $tooltip-padding-y;
$form-feedback-tooltip-padding-x:        $tooltip-padding-x;
$form-feedback-tooltip-font-size:        $tooltip-font-size;
$form-feedback-tooltip-line-height:      $line-height-base;
$form-feedback-tooltip-opacity:          $tooltip-opacity;
$form-feedback-tooltip-border-radius:    $tooltip-border-radius;

// Popovers
$popover-font-size:            $font-size-base;
$popover-bg:                   $white;
$popover-max-width:            276px;
$popover-border-width:         $border-width;
$popover-border-color:         rgba($black, 0.15);
$popover-border-radius:        $border-radius;
$popover-inner-border-radius:  subtract($popover-border-radius, $popover-border-width);
$popover-box-shadow:           0 0.25rem 0.5rem rgba($black, 0.1);

$popover-header-bg:            transparent;
$popover-header-color:         $headings-color;
$popover-header-padding-y:     0.9375rem;
$popover-header-padding-x:     $popover-header-padding-y;

$popover-body-color:           $body-color;
$popover-body-padding-y:       $popover-header-padding-x;
$popover-body-padding-x:       $popover-header-padding-x;

$popover-arrow-width:          0.75rem;
$popover-arrow-height:         0.375rem;
$popover-arrow-color:          $popover-bg;

$popover-arrow-outer-color:    fade-in($popover-border-color, .05);

// Toasts
$toast-max-width:                   350px;
$toast-padding-x:                   1.25rem;
$toast-padding-y:                   0.75rem;
$toast-font-size:                   $font-size-base;
$toast-color:                       $white;
$toast-background-color:            $gray-900;
$toast-border-width:                $border-width;
$toast-border-color:                transparent;
$toast-border-radius:               $border-radius-lg;
$toast-box-shadow:                  0 1px 2px rgba($black, 0.05);

$toast-header-color:                $toast-color;
$toast-header-background-color:     $toast-background-color;
$toast-header-border-color:         rgba($white, 0.1);


//
// Badges
//
// Badges and badge pills. Includes custom variables for optional styles

$badge-font-size:               0.75rem;
$badge-font-weight:             $font-weight-semibold;
$badge-padding-y:               0.3125rem;
$badge-padding-x:               0.4375rem;
$badge-border-radius:           $border-radius-sm;

$badge-transition:              $btn-transition;
$badge-focus-width:             $input-btn-focus-width;

$badge-pill-padding-x:          0.5rem;
$badge-pill-border-radius:      10rem;

$badge-striped-padding-y:       0.4375rem !default;
$badge-striped-padding-x:       0.5rem !default;
$badge-striped-border-width:    2px !default;

$badge-flat-border-width:       1px !default;

$badge-float-margin:            -0.5rem !default;

$badge-mark-border-width:       2px !default;
$badge-mark-size:               0.5rem !default;


//
// Modals
//
// Styles for modal dialogs. Includes custom variables for optional styles

$modal-inner-padding:               1.25rem;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between:       .5rem;

$modal-dialog-margin:               0.5rem;
$modal-dialog-margin-y-sm-up:       1.75rem;

$modal-title-line-height:           $line-height-base;

$modal-content-color:               null;
$modal-content-bg:                  $white;
$modal-content-border-color:        rgba($black, 0.2);
$modal-content-border-width:        $border-width;
$modal-content-border-radius:       $border-radius-lg;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);
$modal-content-box-shadow-xs:       0 0.25rem 0.5rem rgba($black, 0.1);
$modal-content-box-shadow-sm-up:    $modal-content-box-shadow-xs;

$modal-backdrop-bg:                 $black;
$modal-backdrop-opacity:            0.35;
$modal-header-border-color:         rgba($black, 0.125);
$modal-footer-border-color:         $modal-header-border-color;
$modal-header-border-width:         $modal-content-border-width;
$modal-footer-border-width:         $modal-header-border-width;
$modal-header-padding-y:            1.25rem;
$modal-header-padding-x:            1.25rem;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-xl:                          1140px;
$modal-lg:                          900px;
$modal-md:                          600px;
$modal-sm:                          400px;
$modal-xs-width:                    300px !default;
$modal-full-width:                  94% !default;

$panel-lg:                          400px !default;
$panel-md:                          320px !default;
$panel-sm:                          156px !default;
$panel-xs:                          56px !default;

$modal-fade-transform:              translate(0, -50px);
$modal-show-transform:              none;
$modal-transition:                  transform .3s ease-out;
$modal-scale-transform:             scale(1.02);


//
// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:           1rem;
$alert-padding-x:           1.25rem;
$alert-margin-bottom:       $spacer;
$alert-border-radius:       $border-radius;
$alert-link-font-weight:    $font-weight-semibold;
$alert-border-width:        $border-width;

$alert-bg-level:            -10.5;
$alert-border-level:        -1;
$alert-color-level:         6;

$alert-primary-bg:          $blue-100 !default;
$alert-primary-text:        $blue-800 !default;
$alert-primary-border:      $blue-600 !default;
$alert-arrow-size:          5px !default;


//
// Progress bars
//
// Optional sizes are excluded, use inline CSS instead

$progress-height:                  1.25rem;
$progress-font-size:               0.75rem;
$progress-bg:                      $gray-300;
$progress-border-radius:           $border-radius;
$progress-box-shadow:              inset 0 0.0625rem 0.0625rem rgba($black, 0.1);
$progress-bar-color:               $white;
$progress-bar-bg:                  $blue-500;
$progress-bar-animation-timing:    1s linear infinite;
$progress-bar-transition:          width .6s ease;


//
// List group
//
// Replicate sidebar navigation. Includes custom variables for optional styles

$list-group-color:                  null;
$list-group-bg:                     $white;
$list-group-padding-y:              0.5rem !default;

$list-group-border-color:           rgba($black, 0.125);
$list-group-border-width:           $border-width;
$list-group-border-radius:          $border-radius;

$list-group-item-padding-y:         .625rem;
$list-group-item-padding-x:         1.25rem;

$list-group-hover-bg:               $gray-200;
$list-group-active-color:           $component-active-color;
$list-group-active-bg:              $component-active-bg;
$list-group-active-border-color:    $list-group-active-bg;

$list-group-disabled-color:         $gray-600;
$list-group-disabled-bg:            transparent;

$list-group-action-color:           rgba($body-color, 0.85);
$list-group-action-hover-color:     $body-color;

$list-group-action-active-color:    $body-color;
$list-group-action-active-bg:       $gray-200;


//
// Image thumbnails
//
// Polaroid-like image thumbnail styles

$thumbnail-padding:          0.25rem;
$thumbnail-bg:               $body-bg;
$thumbnail-border-width:     $border-width;
$thumbnail-border-color:     $card-border-color;
$thumbnail-border-radius:    $border-radius;
$thumbnail-box-shadow:       0 1px 2px rgba($black, .075);


//
// Figures
//
// Styles for <figure> component

$figure-caption-font-size:    90%;
$figure-caption-color:        $gray-600;


//
// Breadcrumbs
//
// Indicate the current page’s location. Includes custom variables for optional styles

$breadcrumb-font-size:                           null;

$breadcrumb-padding-y:                           ($spacer / 2);
$breadcrumb-padding-x:                           0;
$breadcrumb-item-padding:                        ($spacer / 2);
$breadcrumb-margin-bottom:                       0;
$breadcrumb-bg:                                  transparent;
$breadcrumb-divider-color:                       inherit;
$breadcrumb-active-color:                        $gray-600;
$breadcrumb-divider:                             quote("/");

$breadcrumb-border-radius:                       0;

$breadcrumb-line-padding-x:                      $grid-gutter-width !default;
$breadcrumb-line-border-width:                   $border-width !default;

$breadcrumb-line-light-bg:                       #fafafa !default;
$breadcrumb-line-light-border-color:             $border-color !default;

$breadcrumb-line-dark-bg:                        #273246 !default;
$breadcrumb-line-dark-border-color:              rgba($white, 0.25) !default;


//
// Carousel
//
// A slideshow component for cycling through elements

$carousel-control-color:                $white;
$carousel-control-width:                15%;
$carousel-control-opacity:              0.5;
$carousel-control-hover-opacity:        0.9;
$carousel-control-transition:           opacity 0.15s ease;

$carousel-indicator-width:              30px;
$carousel-indicator-height:             3px;
$carousel-indicator-hit-area-height:    10px;
$carousel-indicator-spacer:             3px;
$carousel-indicator-active-bg:          $white;
$carousel-indicator-transition:         opacity 0.6s ease;

$carousel-caption-width:                70%;
$carousel-caption-color:                $white;

$carousel-control-icon-width:           20px;

$carousel-control-prev-icon-bg:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>");
$carousel-control-next-icon-bg:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>");

$carousel-transition-duration:          0.6s;
$carousel-transition:                   transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)



//
// Spinner
//
// Loading spinners, useful to display loading progress

$spinner-width:           2rem;
$spinner-height:          $spinner-width;
$spinner-border-width:    0.25em;

$spinner-width-sm:           1rem;
$spinner-height-sm:          $spinner-width-sm;
$spinner-border-width-sm:    0.2em;


//
// Close
//
// Close button, mainly used in modal dialogs

$close-font-size:      $font-size-base * $line-height-base;
$close-font-weight:    $font-weight-base;
$close-color:          $body-color;
$close-text-shadow:    none;


//
// Code
//
// Inline and block elements to represent the code

$code-font-size:               $font-size-sm;
$code-color:                   $pink-600;
$code-padding-y:               0.2rem !default;
$code-padding-x:               0.4rem !default;
$code-bg:                      $gray-100 !default;

$kbd-padding-y:                0.2rem;
$kbd-padding-x:                0.4rem;
$kbd-font-size:                $code-font-size;
$kbd-color:                    $white;
$kbd-bg:                       $gray-900;

$pre-color:                    $gray-900;
$pre-scrollable-max-height:    450px;
$pre-padding-y:                1.25rem !default;
$pre-padding-x:                1.25rem !default;
$pre-border-color:             $gray-400 !default;


//
// Utilities
//
// Various options

$displays:     none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;
$overflows:    auto, hidden;
$positions:    static, relative, absolute, fixed, sticky;
$user-selects: all, auto, none;


//
// Printing
//
// Styles for printing

$print-page-size:         a3;
$print-body-min-width:    map-get($grid-breakpoints, "lg");

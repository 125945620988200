/* ------------------------------------------------------------------------------
 *
 *  # Custom Limitless functions
 *
 *  Utility mixins and functions for evalutating source code across our variables, maps, and mixins.
 *
 * ---------------------------------------------------------------------------- */

//
// Color palette
//

// Tint a color: mix a color with white
@function tint-color($color, $weight) {
	@return mix(white, $color, $weight);
}

// Shade a color: mix a color with black
@function shade-color($color, $weight) {
	@return mix(black, $color, $weight);
}


//
// Removes the unit (e.g. px, em, rem) from a value, returning the number only.
//
// @param {Number} $num - Number to strip unit from.
//
// @returns {Number} The same number, sans unit.

@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}


//
// Converts one or more pixel values into matching rem values.
//
// @param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.
// @param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.
//
// @returns {List} A list of converted values.

$global-font-size: 100% !default;

@function rem-calc($values, $base: null) {
	$rem-values: ();
	$count: length($values);

	// If no base is defined, defer to the global font size
	@if $base == null {
		$base: $global-font-size;
	}

	// If the base font size is a %, then multiply it by 16px
	// This is because 100% font size = 16px in most all browsers
	@if unit($base) == '%' {
		$base: ($base / 100%) * 16px;
	}

	// Using rem as base allows correct scaling
	@if unit($base) == 'rem' {
		$base: strip-unit($base) * 16px;
	}

	@if $count == 1 {
		@return -zf-to-rem($values, $base);
	}

	@for $i from 1 through $count {
		$rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
	}

	@return $rem-values;
}


//
// Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
// @access private
//
// @param {Number} $value - Pixel value to convert.
// @param {Number} $base [null] - Base for pixel conversion.
//
// @returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.

@function -zf-to-rem($value, $base: null) {

	// Check if the value is a number
	@if type-of($value) != 'number' {
		@warn inspect($value) + ' was passed to rem-calc(), which is not a number.';
		@return $value;
	}

	// Transform em into rem if someone hands over 'em's
	@if unit($value) == 'em' {
		$value: strip-unit($value) * 1rem;
	}

	// Calculate rem if units for $value is not rem or em
	@if unit($value) != 'rem' {
		$value: strip-unit($value) / strip-unit($base) * 1rem;
	}

	// Turn 0rem into 0
	@if $value == 0rem {
		$value: 0;
	}

	@return $value;
}


//
// Round a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @param  {String} [$mode:round] (round|ceil|floor) How to round a number
// @return {Number} A rounded number
// @example
//     decimal-round(0.333)    => 0
//     decimal-round(0.333, 1) => 0.3
//     decimal-round(0.333, 2) => 0.33
//     decimal-round(0.666)    => 1
//     decimal-round(0.666, 1) => 0.7
//     decimal-round(0.666, 2) => 0.67
//

@function decimal-round($number, $digits: 0, $mode: round) {
    $n: 1;
    // $number must be a number
    @if type-of($number) != number {
        @warn '#{ $number } is not a number.';
        @return $number;
    }
    // $digits must be a unitless number
    @if type-of($digits) != number {
        @warn '#{ $digits } is not a number.';
        @return $number;
    } @else if not unitless($digits) {
        @warn '#{ $digits } has a unit.';
        @return $number;
    }
    @for $i from 1 through $digits {
        $n: $n * 10;
    }
    @if $mode == round {
        @return round($number * $n) / $n;
    } @else if $mode == ceil {
        @return ceil($number * $n) / $n;
    } @else if $mode == floor {
        @return floor($number * $n) / $n;
    } @else {
        @warn '#{ $mode } is undefined keyword.';
        @return $number;
    }
}


// Ceil a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @return {Number} A ceiled number
// @example
//     decimal-ceil(0.333)    => 1
//     decimal-ceil(0.333, 1) => 0.4
//     decimal-ceil(0.333, 2) => 0.34
//     decimal-ceil(0.666)    => 1
//     decimal-ceil(0.666, 1) => 0.7
//     decimal-ceil(0.666, 2) => 0.67
//

@function decimal-ceil ($number, $digits: 0) {
    @return decimal-round($number, $digits, ceil);
}


// Floor a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @return {Number} A floored number
// @example
//     decimal-floor(0.333)    => 0
//     decimal-floor(0.333, 1) => 0.3
//     decimal-floor(0.333, 2) => 0.33
//     decimal-floor(0.666)    => 0
//     decimal-floor(0.666, 1) => 0.6
//     decimal-floor(0.666, 2) => 0.66
//

@function decimal-floor ($number, $digits: 0) {
    @return decimal-round($number, $digits, floor);
}

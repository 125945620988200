/* ------------------------------------------------------------------------------
 *
 *  # Columns reorder
 *
 *  Easily modify the column order of a table through drop-and-drag of column headers
 *
 * ---------------------------------------------------------------------------- */

// Check if component is enabled
@if $enable-datatable-colreorder {

    // Highlight cloned header
    .DTCR_clonedTable {
        background-color: $drag-placeholder-bg;
        z-index: 202;
        cursor: move;

        // Mute background and add border
        th,
        td {
            border: $table-border-width solid $table-border-color!important;
        }
    }

    // Add colored pointer
    .DTCR_pointer {
        width: $table-border-width;
        background-color: $blue-500;
        z-index: 201;
    }
}

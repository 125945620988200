.location-info
{

  tr
  {

    td:not(:last-of-type)
    {

      border-right: solid lightgray 1px;

    }

  }

}
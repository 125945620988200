.bg-grey
{

  background-color: #f5f5f5;

}

.bg-dark-grey
{

  background-color: #909090;

}
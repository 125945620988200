/* ------------------------------------------------------------------------------
 *
 *  # Dragula - drag and drop library
 *
 *  Styles for Dragula Drag and drop plugin
 *
 * ---------------------------------------------------------------------------- */

// Check if component is enabled
@if $enable-dragula {

    // Base
    .gu-mirror {
        position: fixed !important;
        margin: 0 !important;
        z-index: 9999 !important;
        opacity: 0.8;
    }

    // Remove bottom margin from the second last item in dropdown menu
    .gu-unselectable.dropdown-menu li:nth-last-child(2) {
        margin-bottom: 0;
    }


    // Remove borders from toolbar pills
    .nav-pills-toolbar > li.gu-mirror:not(.active) > a {
        border: 0;
    }

    // Hide element
    .gu-hide {
        display: none !important;
    }

    // Disable selection
    .gu-unselectable {
        user-select: none;
    }

    // Mute draggable element
    .gu-transit {
        opacity: 0.5;
    }

    // Draggable handle
    .dragula-handle {
        color: $gray-600;
        cursor: move;

        // Hover state
        @include hover-focus {
            color: $body-color;
        }
    }
}

/* ------------------------------------------------------------------------------
 *
 *  # Error pages
 *
 *  Styles for error and offline pages
 *
 * ---------------------------------------------------------------------------- */

// Check if component is enabled
@if $enable-error {

	// Default title
	.error-title { 
		color: $error-title-color; 
		font-size: $error-title-font-size-sm; 
		line-height: 1; 
		margin-bottom: ($spacer * 2); 
		font-weight: $font-weight-light; 
		text-stroke: 1px transparent; 
		display: block; 
		text-shadow: 0 1px 0 #ccc,
					 0 2px 0 #c9c9c9,
					 0 3px 0 #bbb,
					 0 4px 0 #b9b9b9,
					 0 5px 0 #aaa,
					 0 6px 1px rgba($black, 0.1),
					 0 0 5px rgba($black, 0.1),
					 0 1px 3px rgba($black, 0.3),
					 0 3px 5px rgba($black, 0.2),
					 0 5px 10px rgba($black, 0.25),
					 0 10px 10px rgba($black, 0.2),
					 0 20px 20px rgba($black, 0.15);

		// Use bigger font size on desktop
		@include media-breakpoint-up(sm) {
			font-size: $error-title-font-size;
		}
	}

	// Title in offline page
	.offline-title { 
		font-size: $error-offline-title-font-size-sm; 

		// Use bigger font size on desktop
		@include media-breakpoint-up(sm) {
			font-size: $error-offline-title-font-size; 
		}
	}
}

.user-locations
{

  text-align: center;

  th
  {
    padding-bottom: 0.5rem;
  }

  td
  {

    border-top: 1px solid lightgrey;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

  }

}
/* ------------------------------------------------------------------------------
 *
 *  # Key Table extension
 *
 *  KeyTable provides Excel like cell navigation on any table. Events (focus, blur,
 *  action etc) can be assigned to individual cells, columns, rows or all cells.
 *
 * ---------------------------------------------------------------------------- */

// Check if component is enabled
@if $enable-datatable-keytable {

    // Focus colors
    .dataTable {
        th,
        td {

            // Default
            &.focus {
                outline: ($table-border-width * 2) solid $blue-500;
                outline-offset: -($table-border-width);
            }

            // Success
            &.focus-success {
                outline-color: $green-500;
            }

            // Info
            &.focus-info {
                outline-color: $cyan-500;
            }

            // Warning
            &.focus-warning {
                outline-color: $orange-500;
            }

            // Danger
            &.focus-danger {
                outline-color: $red-500;
            }
        }
    }
}

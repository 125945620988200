#loader-holder
{

  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: rgba(0,0,0, 0.5);
  z-index: 1000000000000;

  #loader
  {

    background-color: #ffffff;
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5%;
    padding: 5em;

    .h1
    {

      width: 100%;
      text-align: center;

    }


    .lds-facebook {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;

      div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: #25b272;
        animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

        &:nth-child(1) {
          left: 8px;
          animation-delay: -0.24s;
        }

        &:nth-child(2) {
          left: 32px;
          animation-delay: -0.12s;
        }

        &:nth-child(3) {
          left: 56px;
          animation-delay: 0s;
        }
      }
    }

  }

}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }

  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

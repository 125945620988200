/* ------------------------------------------------------------------------------
 *
 *  # Custom template mixins
 *
 *  All custom mixins are prefixed with "ll-" to avoid conflicts
 *
 * ---------------------------------------------------------------------------- */


// Content
// ------------------------------

// Font smoothing
@mixin ll-font-smoothing() {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

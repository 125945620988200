.sidebar-user
{

  img
  {

    &.companyLogo
    {

      width: 80%;
      height: unset;
      margin-left: auto;
      margin-right: auto;
      display: block;

    }

  }

}


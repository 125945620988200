.table-location
{

  width: 30%;

  tr
  {

    td
    {

      padding: 5%;
      border: solid lightgray 1px;

    }

    td:first-child
    {

      font-weight: bold;

    }

  }

}
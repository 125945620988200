/* ------------------------------------------------------------------------------
 *
 *  # Background mixin
 *
 *  Override and extend default background mixin.
 *
 * ---------------------------------------------------------------------------- */

// Contextual backgrounds
@mixin bg-variant($parent, $color, $ignore-warning: false) {
	#{$parent} {
		background-color: $color !important;
	}
	a#{$parent},
	button#{$parent} {
		@include transition($btn-transition);
		@include hover-focus() {
			background-color: darken($color, 10%) !important;
		}
	}
	@include deprecate("The `bg-variant` mixin", "v4.4.0", "v5", $ignore-warning);
}
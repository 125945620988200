/* ------------------------------------------------------------------------------
*
*  # Vector maps
*
*  Vector map container, tooltip and zooming
*
* ---------------------------------------------------------------------------- */

// Check if component is enabled
@if $enable-vector-maps {

    // Container
    .vector-map-container {
        height: $map-container-height;
    }

    // Map tooltip
    .jvectormap-label {
        position: absolute;
        display: none;
        background-color: $tooltip-bg;
        color: $tooltip-color;
        padding: $tooltip-padding-y $tooltip-padding-x;
        @include border-radius($tooltip-border-radius);
    }

    // Zoom buttons
    .jvectormap-zoomin,
    .jvectormap-zoomout {
        position: absolute;
        left: $card-spacer-x;
        padding: 0.5rem;
        color: white;
        cursor: pointer;
        font-size: $icon-font-size;
        font-weight: $font-weight-light;
        line-height: 0.625rem;
        color: $map-btn-zoom-color;
        background-color: $map-btn-zoom-bg;
        text-align: center;
        @include border-radius($border-radius);
        @include transition(all ease-in-out $component-transition-timer);

        //  Hover state
        @include hover {
            color: $map-btn-zoom-hover-color;
            background-color: $map-btn-zoom-hover-bg;
        }
    }
    .jvectormap-zoomin {
        top: 0.625rem;
    }
    .jvectormap-zoomout {
        top: 2.5rem;
    }
}

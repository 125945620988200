/* ------------------------------------------------------------------------------
*
*  # Header elements
*
*  Display default and custom components in page header, card header and breadcrumbs
*
* ---------------------------------------------------------------------------- */

// Check if component is enabled
@if $enable-header-elements {

    // Base
    .header-elements {

        // Inside card header
        .card-header & {
            padding-top: $card-spacer-y;
        }


        // Inside page header
        .page-header-light &,
        .page-header-dark & {
            padding-bottom: $header-elements-padding-y;
        }


        //
        // Inside breadcrumb line
        //

        // Base
        .breadcrumb-line & {
            margin-left: -($breadcrumb-line-padding-x);
            margin-right: -($breadcrumb-line-padding-x);
            padding: 0 $breadcrumb-line-padding-x;
            border-top: $border-width solid $border-color;
        }

        // Light breadcrumb line
        .breadcrumb-line-light & {
            border-color: $breadcrumb-line-light-border-color;
        }

        // Dark breadcrumb line
        .breadcrumb-line-dark & {
            border-color: $breadcrumb-line-dark-border-color;
        }


        //
        // Misc
        //

        // Remove bottom margin from last form group
        .form-group {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    // Mobile toggler
    .header-elements-toggle,
    .footer-elements-toggle {
        margin-left: auto;
        align-self: center;
    }


    //
    // Generate styles for multiple breakpoints
    //
    // Classes: .header-elements-inline and .header-elements-[breakpoint]-inline
    //

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            .header-elements#{$infix}-inline {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: nowrap;

                // Header elements container
                .header-elements {
                    display: flex!important;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 0;
                    background-color: transparent;
                    border: 0;
                    margin-left: 0;
                    margin-right: 0;
                }

                // Tabs inside card header
                .card-header-tabs {
                    .nav-link {
                        padding-top: $card-header-spacer-y + rem-calc($card-border-width);
                        padding-bottom: $card-header-spacer-y + rem-calc($card-border-width);
                    }
                }
            }
        }
    }
}

.photo-corrective-action
{

  width: 10rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

}

.corrective-action-column-title
{

  width: 15rem;
  font-weight: 500;
  color: #000;
  background: #eee;



}

.corrective-action-checklist-done
{

  overflow-wrap: break-word;
  width: 20rem;
  border-right: solid 1px lightgray;

}

.corrective-action-table
{
  td
  {

    padding: 1rem;
    border: 1px solid #999;

  }
}

.corrective-action-table-comment
{
  td{
    padding: 1em;
  }

  th{
    background: #eee;
  }

  width: 100%;

  .comment-column-date
  {

    width: 10%;
    justify-content: center;

  }

  .comment-column-name
  {

    width: 15%;

  }

  .comment-column-comment
  {

    text-align: justify;

  }

  td, th
  {
    padding: 0.5rem 1rem 0.5rem 1rem;
    border: solid 1px lightgray;

  }

  th
  {

    text-align: center;

  }

}


.corrective-action-checklist
{
  td{

    border: none;
    border-bottom: 1px solid #999;
  }
}
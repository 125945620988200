.question-table
{
  td
  {

    width: 35rem;

  }

  tr
  {

    padding-bottom: 0.5rem;
    padding-top: 0.5rem;

  }
}

.question-table-width
{

  width: 35rem;
  display: inline-flex

}

.question-th
{
  width: 35rem;
}


.question-tr
{

  border-top: 1px solid lightgrey;

}

.question-hr
{

  border-bottom: 1px solid lightgrey;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;

}

.question-table-mini
{

  width: 10rem;
  display: inline-flex

}




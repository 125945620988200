label
{

  &.required
  {

    &::after
    {

      display: inline-block;
      content: '*';
      color: red;
      margin-left: .5em;

    }

  }

}
